.userManager {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-content: flex-start;
}

.userManager .addUserArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
}

.userManager .addUserFields {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.5rem;
  width: 100%;
}

.userManager .addUserFields .formField {
  display: flex;
  flex-direction: row;
  column-gap: 0.3rem;
  justify-content: flex-start;
}

.userManager .addUserFields .formField>*:nth-child(1) {
  min-width: 30%;
}

.userManager .addUserFields .formField>*:nth-child(2) {
  min-width: 50%;
}

.userManager .addUserFields .formField.required:has(.formFieldChoices.empty),
.userManager .addUserFields .formField.required input[type=text][value=""] {
  background-color: rgb(200 0 0 / 0.3);
}

.userManager .addUserFields .formField .formFieldChoices {
  overflow-x: auto;
}

.userManager .addUserFields .formField .formFieldChoice {
  cursor: pointer;
  width: max-content;
}

.userManager .addUserFields .formField .formFieldChoice:hover,
.userManager .addUserFields .formField .formFieldChoice.selected:hover {
  background-color: rgb(100 0 0 / 0.5);
}

.userManager .addUserFields .formField .formFieldChoice.selected {
  background-color: rgb(0 100 0 / 0.25);
}

.addUserFields .formField>label::after {
  content: ":";
}

.userManager>.userTablePane {
  overflow-y: scroll;
  max-height: 85vh;
  border: 1px solid black;
  padding: 0.3rem;
}

.userTable {
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 0.5rem;
  row-gap: 0.2rem;
}

.userTable>.userTableRow {
  display: contents;
}

.userTable>.userTableRow:hover>div {
  background-color: rgb(0 0 0 / 0.25);
  cursor: pointer;
}

.userTable>div:nth-child(-n+4) {
  font-weight: bold;
}

@media only screen and (max-width: 550px) {
  .userManager .addUserFields .formField {
    display: flex;
    flex-direction: column;
  }
}